
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { UserItemEntity } from "../../entities/user-item.entity";
import Item from "@/components/items/Item.vue";

@Component({
  components: {
    Item,
  },
})
export default class UserBoxItem extends Vue {
  @Prop() item!: UserItemEntity;
  @Prop({ default: "" }) textFieldClass!: any;
  @Prop({ default: "" }) textFieldStyle!: any;
  @Prop({ default: false }) readonly!: boolean;
  @VModel({ type: Number }) _value!: number | null;
}
