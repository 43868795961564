
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from "vue-property-decorator";
import BoxTape from "@/components/app/AppTape.vue";
import Price from "@/components/base/Price.vue";
import Item from "@/components/items/Item.vue";
import { SteamItemEntity } from "@/entities/steam-item.entity";
import Box from "@/components/boxes/Box.vue";
import { BoxEntity } from "@/entities/box.entity";
import defaultBoxImage from "@/assets/default-box.png";
import GradientBtn from "@/components/base/GradientBtn.vue";
import { Decimal } from "decimal.js";
import * as minBy from "lodash/minBy";
import * as range from "lodash/range";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import Chance from "chance";
import ItemList from "@/components/items/ItemList.vue";
import BorderContainer from "@/components/base/BorderContainer.vue";
import { UserItemEntity } from "@/entities/user-item.entity";
import { PaginateUserItemWithoutUserQuery } from "@/graphql/queries";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import gql from "graphql-tag";
import { UserItemWithoutUserFragment } from "@/graphql/fragments";
import TopAppBar from "@/components/base/TopAppBar.vue";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import UserBoxItem from "@/components/boxes/UserBoxItem.vue";
import { Howl, Howler } from "howler";
import betSkinAudio from "@/assets/audio/bet.mp3";
import { useScroll } from "@/graphql/use-scroll";
import { useCount } from "@/graphql/use-count";
import { useFindAll } from "@/graphql/use-find-all";
import { mongoTextSearch } from "@/helpers/mongo-text-search.helper";

const CHAT_MESSAGE_AUDIO = new Howl({
  src: [betSkinAudio],
  preload: true,
});

const chance = new Chance();

@Component({
  components: {
    TopAppBar,
    UserBoxItem,
    BaseSlideList,
    BoxTape,
    ScrollFetchMore,
    BorderContainer,
    ItemList,
    GradientBtn,
    Box,
    Item,
    Price,
  },
  metaInfo: {
    title: "Кейс конструктор",
  },
  apollo: {
    userBoxPriceRate: {
      update(data) {
        return data.userBoxPriceRate ? data.userBoxPriceRate : 1;
      },
      query: gql`
        query {
          userBoxPriceRate
        }
      `,
    },
    itemsCount: {
      update(data) {
        return typeof data.countUserItem === "number"
          ? data.countUserItem
          : null;
      },
      debounce: 300,
      query: useCount("UserItem"),
      variables() {
        return {
          filter: {
            userId: { exists: false },
            isNameFirstUnique: { eq: true },
            state: { eq: "DEFAULT" },
          },
        };
      },
    },
    items: {
      update(data) {
        return data.findAllUserItem
          ? data.findAllUserItem.map((item) => new UserItemEntity(item))
          : null;
      },
      debounce: 300,
      fetchPolicy: "cache-and-network",
      query: useFindAll("UserItem", UserItemWithoutUserFragment),
      variables() {
        return {
          options: {
            sort: this.sortBy,
          },
          filter: {
            userId: { exists: false },
            ...(this.searchText
              ? { _text: { search: mongoTextSearch(this.searchText) } }
              : {}),
            price: { gte: this.priceRange[0], lte: this.priceRange[1] },
            isNameFirstUnique: { eq: true },
            state: { eq: "DEFAULT" },
          },
        };
      },
    },
  },
})
export default class UserBox extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private priceRange = [0, 999999];
  private searchText = "";
  private sortBy = "-price";
  private opening = false;
  private showBoxTape = false;
  private items: UserItemEntity[] | null = null;
  private itemsCount: number | null = null;
  private box = new BoxEntity({
    _id: "1",
    name: "123",
    itemsPriceRange: [0, 100],
    image: defaultBoxImage,
    price: 100,
    isAvailable: true,
  });
  private userBoxPriceRate = 1;
  private selectedItems: Array<SteamItemEntity | null> = range(10).map(
    () => null
  );
  private chances: Array<number | null> = range(10).map(() => null);

  @Watch("sortBy")
  @Watch("searchText")
  @Watch("priceRange")
  onVariablesChanged() {
    const scroll = this.$el.querySelector("#user-box-item-list");
    if (scroll) {
      scroll.scrollTop = 0;
    }
  }

  get selectedItemsWithoutNull(): SteamItemEntity[] {
    return this.selectedItems.filter((n) => n !== null) as SteamItemEntity[];
  }

  get chancesSum() {
    return this.chances.reduce((acc, v) => {
      if (v) {
        acc = new Decimal(acc as number).plus(v).toNumber();
      }

      return acc;
    }, 0);
  }

  get openBtnDisabled() {
    return (
      !this.price ||
      this.opening ||
      this.selectedItemsWithoutNull.length < 2 ||
      this.chancesSum !== 100 ||
      !this.user ||
      this.user.balance < this.price ||
      this.chances.some((v) => v === 0)
    );
  }

  get price() {
    return Number(
      (
        this.userBoxPriceRate *
        this.selectedItems.reduce((acc, v, i) => {
          if (v && this.chances[i]) {
            acc += (v.price * (this.chances[i] as number)) / 100;
          }

          return acc;
        }, 0)
      ).toFixed(2)
    );
  }

  setRandomChances() {
    const nonNullItems = this.selectedItems.filter((v) => v !== null);
    const sum = Number(
      nonNullItems
        .reduce((acc, v) => {
          if (v) {
            acc += v.price;
          }

          return acc;
        }, 0)
        .toFixed(2)
    );
    const cheapestItem = minBy(nonNullItems, "price");
    const cheapestItemIndex = this.selectedItems.findIndex(
      (v) => v === cheapestItem
    );
    const cheapestItemChance = chance.floating({ min: 30, max: 99, fixed: 2 });
    this.setChance(cheapestItemChance, cheapestItemIndex);
    const remainOdds = new Decimal(100)
      .minus(cheapestItemChance)
      .div(100)
      .toNumber();
    this.selectedItems.forEach((item, index) => {
      if (item && index !== cheapestItemIndex) {
        const chance =
          nonNullItems.length === 2
            ? 1 - remainOdds
            : ((1 - item.price / (sum - cheapestItem.price)) * remainOdds) /
              (nonNullItems.length - 2);
        this.setChance((chance * 100).toFixed(2), index);
      }
    });
    const remain = new Decimal(100).minus(this.chancesSum as number).toNumber();
    if (cheapestItemIndex !== -1) {
      this.setChance(
        new Decimal(this.chances[cheapestItemIndex] as number)
          .plus(remain)
          .toNumber()
          .toFixed(4),
        cheapestItemIndex
      );
    }
  }

  setChance(value: string | number, index: number) {
    const chance = Number(value);

    if (chance >= 0) {
      this.$set(this.chances, index, chance);
    } else {
      this.$set(this.chances, index, 0);
    }
  }

  select(item: SteamItemEntity) {
    if (this.showBoxTape) {
      return;
    }

    const index = this.selectedItems.findIndex(
      (item1) => item1 && item1._id === item._id
    );

    if (index === -1) {
      let index = this.selectedItems.findIndex((v) => v === null);
      index = index === -1 ? 0 : index;
      this.$set(this.selectedItems, index, item);
      this.$set(this.chances, index, 0);
      CHAT_MESSAGE_AUDIO.play();
    } else {
      this.$set(this.selectedItems, index, null);
      this.$set(this.chances, index, null);
    }
  }

  async skip() {
    const tape = this.$refs.boxTape as BoxTape;
    await tape.skip();
  }

  async close() {
    this.opening = false;
    this.showBoxTape = false;
    this.selectedItems = this.selectedItems.map(() => null);
    this.chances = this.chances.map(() => null);
    await this.$apollo.queries.items.refetch();
  }

  async open() {
    this.opening = true;
    this.showBoxTape = true;

    const item = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: OpenUserBoxInput!) {
            openUserBox(input: $input) {
              profitItem {
                ...UserItemWithoutUser
              }
            }
          }
          ${UserItemWithoutUserFragment}
        `,
        variables: {
          input: {
            userItemIds: (
              this.selectedItems.filter((n) => n !== null) as SteamItemEntity[]
            ).map(({ _id }) => _id),
            weights: (this.chances.filter((n) => n !== null) as number[]).map(
              (chance) => new Decimal(chance).div(100).toNumber()
            ),
          },
        },
      })
      .then(
        ({
          data: {
            openUserBox: { profitItem: item },
          },
        }) => new UserItemEntity(item)
      )
      .catch((e) => {
        console.error(e);
      });

    if (!item) {
      this.$notify({
        type: "info",
        text: "К сожалению, некоторые предметы уже недоступны.",
      });

      return this.close();
    }

    await this.$nextTick();
    const tape = this.$refs.boxTape as BoxTape;
    await tape.animate(item);
    this.opening = false;

    this.$notify({
      text: `Поздравляем, вы выиграли предмет за ${item.price} руб. Он добавлен в Ваш инвентарь.`,
      title: item.name,
      data: {
        class: `notification-${item.rarity}`,
        image: item.image,
      },
    });
  }
}
